import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { dateToString } from "shared/helpers";
import CircularProgress from "@mui/material/CircularProgress";
import { IconButton } from "@mui/material";
import { KebabMenu } from "components/kebab";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarsIcon from "@mui/icons-material/Stars";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import TooltipComponent from "components/tooltip";
import { useStyles } from "./styles";

const ModelBody = ({
  group,
  id,
  getBoldClass,
  readyModel,
  partiallyDeployedModel,
  generateItems,
  isModel,
}: any) => {
  const { t } = useTranslation();
  const tt = (i: string) => t(`machine.detail.model.${i}`);
  const classes = useStyles();

  const [isTaskId, setIsTaskId] = useState(false);
  const [taskIdForTooltip, setTaskIdForTooltip] = useState(false);

  const round2Dec = (i: number) => Math.round(i * 100) / 100;

  const specialModel = group.filter(
    (item: any) => item.status === "training" || readyModel(item)
  );

  useEffect(() => {
    if (specialModel.length !== 0) {
      setIsTaskId(true);
    }
  }, []);

  return (
    <>
      <thead className={classes.break} />
      <TableBody className={classes.tableContent}>
        <TableRow className={classes.tr}>
          <TableCell className={classes.td}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setIsTaskId(!isTaskId);
              }}
            >
              {isTaskId ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell
            colSpan={4}
            className={`${classes.smallPadding} ${classes.td}`}
          >
            {isModel ? (
              <strong>
                {group[0].machine} - {group[0].project_name} ({group[0].project}
                ):
              </strong>
            ) : (
              <strong>{id}:</strong>
            )}
            &nbsp;
            {dateToString(new Date(group[0].task_created_at))}
            &nbsp;-&nbsp;
            {group.filter((i: any) => i.status === "training").length
              ? tt("running")
              : group.filter((i: any) => i.finished && !i.success).length ===
                  group.length
                ? tt("failed")
                : dateToString(
                    group
                      .map((i: any) => new Date(i.training_finished_at))
                      .sort()[group.length - 1]
                  )}
          </TableCell>
          <TableCell
            colSpan={8}
            className={`${classes.smallPadding} ${classes.td}`}
          >
            {group[0].task_url}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.td}`}
            align="left"
          >
            {group.filter((i: any) => i.metrics?.environment)[0]?.metrics
              ?.environment || "?"}
          </TableCell>
        </TableRow>
        {isTaskId
          ? group.map((model: any) => {
              const tooltipInnerForAdditionalInfo = (
                <Box>
                  <div>
                    <span
                      className={classes.smallPadding}
                      title={tt("longAugmentAUC")}
                    >
                      {tt("augmentAUC")}:
                    </span>
                    <strong>
                      {round2Dec(model.metrics?.metrics?.augment_AUC)}
                    </strong>
                  </div>
                  <div>
                    <strong
                      className={classes.smallPadding}
                      title={tt("longAugmentFN")}
                    >
                      {tt("augmentFN")}:
                    </strong>
                    <span className={classes.smallPadding}>
                      {round2Dec(model.metrics?.metrics?.augment_FN)}
                    </span>
                  </div>
                  <div>
                    <span
                      className={classes.smallPadding}
                      title={tt("longAugmentFP")}
                    >
                      {tt("augmentFP")}:
                    </span>
                    <strong>
                      {round2Dec(model.metrics?.metrics?.augment_FP)}
                    </strong>
                  </div>
                  <div>
                    <span
                      className={classes.smallPadding}
                      title={tt("longAugmentTN")}
                    >
                      {tt("augmentTN")}:
                    </span>
                    <strong>
                      {round2Dec(model.metrics?.metrics?.augment_TN)}
                    </strong>
                  </div>
                  <div>
                    <span
                      className={classes.smallPadding}
                      title={tt("longAugmentTP")}
                    >
                      {tt("augmentTP")}:
                    </span>
                    <strong>
                      {round2Dec(model.metrics?.metrics?.augment_TP)}
                    </strong>
                  </div>
                  <div>
                    <span
                      className={classes.smallPadding}
                      title={tt("longAugmentAccuracy")}
                    >
                      {tt("augmentAccuracy")}:
                    </span>
                    <strong>
                      {round2Dec(model.metrics?.metrics?.augment_accuracy)}
                    </strong>
                  </div>
                  <div>
                    <span
                      className={classes.smallPadding}
                      title={tt("longAugmentPrecision")}
                    >
                      {tt("augmentPrecision")}:
                    </span>
                    <strong>
                      {round2Dec(model.metrics?.metrics?.augment_precision)}
                    </strong>
                  </div>
                  <div>
                    <span
                      className={classes.smallPadding}
                      title={tt("longAugmentRecall")}
                    >
                      {tt("augmentRecall")}:
                    </span>
                    <strong>
                      {round2Dec(model.metrics?.metrics?.augment_recall)}
                    </strong>
                  </div>
                  <div>
                    <span
                      className={classes.smallPadding}
                      title={tt("longSanityMax")}
                    >
                      {tt("sanityMax")}:
                    </span>
                    <strong>
                      {round2Dec(model.metrics?.metrics?.sanity_max)}
                    </strong>
                  </div>
                  <div>
                    <span
                      className={classes.smallPadding}
                      title={tt("longSanityMean")}
                    >
                      {tt("sanityMean")}:
                    </span>
                    <strong>
                      {round2Dec(model.metrics?.metrics?.sanity_mean)}
                    </strong>
                  </div>
                  <div>
                    <span
                      className={classes.smallPadding}
                      title={tt("longSanityMin")}
                    >
                      {tt("sanityMin")}:
                    </span>
                    <strong>
                      {round2Dec(model.metrics?.metrics?.sanity_min)}
                    </strong>
                  </div>
                  <div>
                    <span
                      className={classes.smallPadding}
                      title={tt("longSanityStd")}
                    >
                      {tt("sanityStd")}:
                    </span>
                    <strong>
                      {round2Dec(model.metrics?.metrics?.sanity_std)}
                    </strong>
                  </div>
                </Box>
              );

              const tooltipInnerStatus = (
                <span>
                  {model.status === "not the best"
                    ? tt("trainingSuccessful")
                    : model.status === "selected"
                      ? tt("modelSelected")
                      : model.status === "training failed"
                        ? tt("trainingFailed")
                        : tt("metricsFailed")}
                </span>
              );
              const tooltipInnerDeploymentStatus = (
                <span>
                  {readyModel(model)
                    ? tt("modelDeployed")
                    : tt("modelUndeployed")}
                </span>
              );
              const tooltipInnerSanity = <span>{tt("underThreshold")}</span>;

              const handleTooltipHover = () => {
                setTaskIdForTooltip(!taskIdForTooltip);
              };

              return (
                <TableRow key={model.task_id}>
                  <TableCell className={`${classes.td} ${classes.tdCollapsed}`}>
                    <TooltipComponent
                      title={tooltipInnerDeploymentStatus}
                      onOpen={handleTooltipHover}
                    >
                      {model.deployment ? (
                        readyModel(model) ? (
                          <PlayCircleFilledIcon
                            className={
                              model.deployment?.environment === "production"
                                ? classes.production
                                : classes.test
                            }
                          />
                        ) : !model.deployment?.is_deleted &&
                          !model.deployment?.is_ready ? (
                          <div className={classes.smallSpinner}>
                            <CircularProgress
                              className={
                                !readyModel(model) &&
                                partiallyDeployedModel(model)
                                  ? classes.partiallyDeployed
                                  : classes.lightGrey
                              }
                              size={15}
                            />
                          </div>
                        ) : (
                          <RemoveCircleOutlineIcon
                            className={
                              model?.deployment?.environment === "production"
                                ? classes.undeployedProduction
                                : classes.undeployedTest
                            }
                          />
                        )
                      ) : (
                        <></>
                      )}
                    </TooltipComponent>
                  </TableCell>
                  <TableCell
                    className={`${classes.td} ${
                      classes.tdCollapsed
                    } ${getBoldClass(model, classes.smallPadding)}`}
                  >
                    {model.model_type_name}
                  </TableCell>
                  <TableCell
                    className={`${classes.td} ${
                      classes.tdCollapsed
                    } ${getBoldClass(model, classes.smallPadding)}`}
                  >
                    {model.trained_model}
                  </TableCell>
                  <TableCell
                    className={`${classes.td} ${classes.tdCollapsed} ${classes.smallPadding}`}
                  >
                    {model.status === "training" ? (
                      <CircularProgress size={19} />
                    ) : (
                      <TooltipComponent
                        title={tooltipInnerStatus}
                        onOpen={handleTooltipHover}
                      >
                        {model.status === "not the best" ? (
                          <CheckCircleIcon style={{ color: "green" }} />
                        ) : model.status === "selected" ? (
                          <StarsIcon style={{ color: "green" }} />
                        ) : (
                          <ErrorIcon style={{ color: "red" }} />
                        )}
                      </TooltipComponent>
                    )}
                  </TableCell>
                  <TableCell
                    className={`${classes.td} ${
                      classes.tdCollapsed
                    } ${getBoldClass(model, classes.smallPadding)}`}
                  >
                    <div className={classes.metricSanity}>
                      <span>
                        {round2Dec(model.metrics?.metrics?.sanity_class_metric)}
                      </span>
                      {model.metrics?.metrics?.sanity_class_metric < 0.8 ? (
                        <TooltipComponent
                          title={tooltipInnerSanity}
                          onOpen={handleTooltipHover}
                        >
                          <WarningIcon className={classes.metricTooLow} />
                        </TooltipComponent>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell
                    className={`${classes.td} ${
                      classes.tdCollapsed
                    } ${getBoldClass(model, classes.smallPadding)}`}
                  >
                    <div className={classes.metricSanity}>
                      <span>
                        {round2Dec(model.metrics?.metrics?.sanity_metric)}
                      </span>
                      {model.metrics?.metrics?.sanity_metric < 0.7 ? (
                        <TooltipComponent
                          title={tooltipInnerSanity}
                          onOpen={handleTooltipHover}
                        >
                          <WarningIcon className={classes.metricTooLow} />
                        </TooltipComponent>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell
                    className={`${classes.td} ${
                      classes.tdCollapsed
                    } ${getBoldClass(model, classes.smallPadding)}`}
                  >
                    {round2Dec(model.metrics?.metrics?.augment_f1)}
                  </TableCell>
                  <TableCell
                    className={`${classes.td} ${classes.tdCollapsed} ${classes.statusPadding}`}
                  >
                    <TooltipComponent
                      title={tooltipInnerForAdditionalInfo}
                      onOpen={handleTooltipHover}
                    >
                      <InfoIcon />
                    </TooltipComponent>
                  </TableCell>
                  <TableCell
                    className={`${classes.td} ${
                      classes.tdCollapsed
                    } ${getBoldClass(model, classes.smallPadding)}`}
                  >
                    {model?.deployment?.environment}
                  </TableCell>
                  <TableCell
                    className={`${classes.td} ${
                      classes.tdCollapsed
                    } ${getBoldClass(model, classes.smallPadding)}`}
                  >
                    {model?.deployment?.deployment_type
                      ? t(
                          `serviceManager.location.${model?.deployment?.deployment_type}`
                        )
                      : ""}
                  </TableCell>
                  <TableCell
                    className={`${classes.td} ${
                      classes.tdCollapsed
                    } ${getBoldClass(model, classes.smallPadding)}`}
                  >
                    {readyModel(model) || partiallyDeployedModel(model)
                      ? model.deployment?.id
                      : ""}
                    {!readyModel(model) && partiallyDeployedModel(model) ? (
                      <div className={classes.smallSpinner}>
                        <CircularProgress size={15} />
                      </div>
                    ) : null}
                  </TableCell>
                  <TableCell
                    className={`${classes.td} ${
                      classes.tdCollapsed
                    } ${getBoldClass(model, classes.smallPadding)}`}
                  >
                    {model.deployment?.created_at
                      ? dateToString(new Date(model.deployment?.created_at))
                      : ""}
                  </TableCell>
                  <TableCell
                    className={`${classes.td} ${
                      classes.tdCollapsed
                    } ${getBoldClass(model, classes.smallPadding)}`}
                  >
                    {model.deployment?.lastActivity
                      ? dateToString(new Date(model.deployment?.lastActivity))
                      : ""}
                  </TableCell>
                  <TableCell
                    className={`${classes.td} ${classes.tdCollapsed} ${classes.smallPadding}`}
                  >
                    {isModel ? (
                      <KebabMenu
                        id={"kebab-devices-list-" + model.task_id}
                        data={generateItems(model)}
                      />
                    ) : model.status !== "training failed" ? (
                      <KebabMenu
                        id={"kebab-devices-list-" + model.task_id}
                        data={generateItems(model)}
                      />
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })
          : null}
      </TableBody>
    </>
  );
};

export default ModelBody;
