import React, { useEffect, useRef, useState } from "react";
import { CalendarIcon } from "@mui/x-date-pickers";
import DateTimePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useStyles } from "./styles";
import dayjs from "dayjs";

type Props = {
  value: Date | null;
  onChange: (date?: any) => void;
  label: string;
  showTimeSelect?: boolean;
  preventOpenOnFocus?: boolean;
  dateFormat?: string;
  hasError?: boolean;
  setHasError?: any;
  id: string;
  maxDate?: Date;
  minDate?: Date;
  disableFuture?: boolean;
  icon?: any;
  disableOpenPicker?: boolean;
  onBlur?: any;
  onKeyDown?: any;
  isTimePicker?: boolean;
  disabled?: boolean;
  helperText?: string | null;
  padding?: any;
  dateWithFormatting?: boolean;
  open?: boolean;
  onClose?: () => void;
};

const CustomPicker = <T extends object>({
  value,
  onChange,
  label,
  showTimeSelect = true,
  preventOpenOnFocus = true,
  dateFormat,
  hasError,
  setHasError,
  id,
  maxDate,
  minDate,
  disableFuture,
  icon,
  disableOpenPicker,
  onBlur,
  onKeyDown,
  isTimePicker,
  disabled,
  helperText,
  padding,
  dateWithFormatting,
  open,
  onClose,
  ...props
}: T & Props) => {
  const [openCalendar, setOpenCalendar] = useState(open ? open : false);
  const [isFocused, setIsFocused] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const datePickerRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);
  const classes = useStyles({
    showTimeSelect,
    isValid: hasError || !!helperText || isValid,
    disabled,
  });
  const [date, setDate] = useState<any>(value ? dayjs(value).toDate() : null);

  const handleDateChange = (value: any) => {
    onChange(dayjs(value));
    setDate(value);
  };

  const handleIconClick = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setOpenCalendar((prev) => !prev);
    setIsFocused((prev) => !prev);
  };

  const placeholderText = (dateFormat ?? "yyyy/MM/dd hh:mm:ss").split(" ");
  const splitFormatting = `${placeholderText[0].toUpperCase()} ${placeholderText.length > 1 ? placeholderText[placeholderText.length - 1].toLowerCase() : ""}`;

  return (
    <>
      <fieldset
        onBlur={onBlur}
        className={classes.pickerwrapper}
        onKeyDown={onKeyDown}
        ref={wrapperRef}
      >
        <label
          htmlFor={id}
          className={classes.label}
          style={{
            top: date || isFocused ? "-1.7rem" : "-0.5rem",
            fontSize: date || isFocused ? "14px" : "1rem",
          }}
        >
          {label}
        </label>
        <legend
          style={{
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            transform: "scale(0.75)",
            visibility: "hidden",
            maxWidth: date || isFocused ? "100%" : 0,
            padding: date || isFocused ? padding || 0 : 0,
            height: 0,
          }}
        >
          {label}
        </legend>
        <div>
          <DateTimePicker
            id={id}
            selected={date ?? null}
            onChange={handleDateChange}
            dateFormat={dateFormat ?? "yyyy/MM/dd hh:mm:ss"}
            calendarIconClassName={classes.calendarIcon}
            placeholderText={isFocused ? splitFormatting : undefined}
            showTimeSelect={showTimeSelect}
            ref={datePickerRef}
            preventOpenOnFocus={preventOpenOnFocus}
            onFocus={() => {
              setIsFocused(true);
            }}
            shouldCloseOnSelect={false}
            showPopperArrow={false}
            open={openCalendar}
            onChangeRaw={(event: any) => {
              const date = event.target.value;
              const formatting = splitFormatting;
              const invalidDate = dateWithFormatting
                ? dayjs(date, formatting)
                : dayjs(date);
              if (!isTimePicker) {
                if (isNaN(invalidDate as any)) {
                  setHasError?.(true);
                  setIsValid(true);
                  return false;
                }
                setHasError?.(false);
                setIsValid(false);
              }
            }}
            onClickOutside={(event: any) => {
              if (
                wrapperRef?.current &&
                !wrapperRef?.current?.contains(event.target)
              ) {
                setOpenCalendar(false);
                setIsFocused(false);
                onClose?.();
              }
            }}
            className={classes.picker}
            onSelect={() => {
              setOpenCalendar(true);
            }}
            maxDate={disableFuture ? new Date() : maxDate}
            minDate={minDate}
            disabled={disabled}
            {...props}
          />
        </div>

        {!disableOpenPicker ? (
          <div
            onClick={!disabled ? handleIconClick : () => {}}
            style={{ color: `${disabled ? "rgba(0, 0, 0, 0.38)" : "unset"}` }}
          >
            {icon ? icon : <CalendarIcon />}
          </div>
        ) : (
          <></>
        )}
      </fieldset>
      {helperText ? (
        <p
          style={{
            fontSize: ".75rem",
            color: "#d32f2f",
            lineHeight: 1.66,
            margin: "0.25rem 0.75rem 0px",
          }}
        >
          {helperText}
        </p>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomPicker;
