async function limitConcurrency(tasks: any[], limit: number) {
  const results: any[] = [];
  const executing: any = new Set();

  async function runTask(task: any) {
    const promise = task()
      .then((result: any) => {
        results.push(result);
      })
      .catch((err: any) => {
        throw err;
      })
      .finally(() => {
        executing.delete(promise);
      });
    executing.add(promise);

    if (executing.size >= limit) {
      await Promise.race(executing);
    }
  }

  let idx = 0;
  for (const task of tasks.map((t) => [t, idx++])) {
    await runTask(async () => {
      return [await task[0](), task[1]];
    });
  }

  // Wait for all remaining tasks to complete
  await Promise.all(executing);
  return results.sort((a, b) => (a[1] < b[1] ? -1 : 1)).map((i) => i[0]);
}

export { limitConcurrency };
