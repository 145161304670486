import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import IMuiTheme from "types/styles";

type Props = {
  showTimeSelect?: boolean;
  isValid?: boolean;
  disabled?: boolean;
};

export const useStyles = makeStyles<IMuiTheme | Theme, Props>(
  (theme: IMuiTheme | Theme) => ({
    wrapper: {
      padding: theme.spacing(1.5, 0),
    },
    container: {
      justifyContent: "flex-start",
      alignItems: "center",
      flexWrap: "wrap",
    },
    picker: {
      width: "100%",
    },
    input: {
      paddingTop: theme.typography.pxToRem(10.5),
      paddingBottom: theme.typography.pxToRem(10.5),
    },
    buttons: {
      display: "flex",
      gap: theme.spacing(1),
    },
    calendarIcon: {
      right: 0,
      cursor: "pointer",
      display: "none",
    },
    outlinedDatepicker: {
      "& input": {
        border: "1px solid #ccc",
        padding: "8px 12px",
        borderRadius: "4px",
        backgroundColor: "white",
        outline: "none",
        font: "inherit",
      },
    },
    pickerwrapper: {
      border: "1px solid gray",
      display: "flex",
      borderRadius: "4px",
      cursor: ({ disabled }) => (disabled ? "default" : "pointer"),
      justifyContent: "space-between",
      alignItems: "end",
      padding: "0 8px",
      textAlign: "left",
      margin: 0,
      position: "relative",
      height: "auto",
      borderColor: ({ isValid, disabled }) =>
        disabled
          ? theme.custom.palette.shade
          : isValid
            ? "#d32f2f"
            : theme.custom.palette.placeholder,

      "& input": {
        border: "none",
        outline: "none",
        paddingBottom: "6.5px",
        paddingTop: "6.5px",
        font: "inherit",
        color: ({ disabled }) =>
          disabled ? "rgba(0, 0, 0, 0.38)" : theme.custom.palette.data,
      },
      "& .react-datepicker": {
        width: ({ showTimeSelect }) => (showTimeSelect ? "328px" : "auto"),
      },
      "& .react-datepicker-popper": {
        zIndex: 99999,
        // left: "4.2rem!important",
        left: "20%!important",
      },
    },

    label: {
      color: ({ isValid, disabled }) =>
        disabled
          ? "rgba(0, 0, 0, 0.38)"
          : isValid
            ? "#d32f2f"
            : "rgba(0, 0, 0, 0.6)",
      fontFamily: "'Fira Sans', sans-serif",
      fontWeight: 400,
      lineHeight: "1.4375em",
      padding: 0,
      display: "block",
      transformOrigin: "top left",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "calc(100% - 24px)",
      position: "absolute",
      left: 0,
      "-webkit-transform": "translate(14px, 16px) scale(1)",
      "-moz-transform": "translate(14px, 16px) scale(1)",
      "-ms-transform": "translate(14px, 16px) scale(1)",
      transform: "translate(14px, 16px) scale(1)",
      "-webkit-transition":
        "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      transition:
        "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      zIndex: 1,
      pointerEvents: "none",
    },
  })
);
