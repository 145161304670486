import Grid from "@mui/material/Grid";
import SearchMultiSelect from "components/searchMultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect } from "react";
import { fetchStaticData } from "store/machineDetail/sounds/actions";
import { AppState } from "store";
import Button from "@mui/material/Button";
import { eventLabelsKey } from "shared/uniqueQueryKeys";
import { useQuery } from "react-query";
import getEventLabels from "api/handlers/event/getEventLabels";
import { actions } from "store/eventModal/slice";
import {
  LabelGroupModalState,
  LabelModalState,
  ELabelDateType,
} from "store/eventModal/types";
import { DateTimePicker } from "@mui/x-date-pickers";
import Picker from "localization/pickerLocale";
import Box from "@mui/material/Box";
import {
  mapLabelGroups,
  validateLabelPickers,
} from "components/events/helpers";
import { createSelector } from "reselect";
import cloneDeep from "lodash/cloneDeep";
import Divider from "components/typography/heading/divider/index";
import { IPlacementList } from "types/placement";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";
import CustomPicker from "components/customPickers";

const selector = createSelector(
  (state: AppState) => state.eventModal,
  (state: AppState) => state.machineDetail,
  (
    {
      modalType,
      eventType,
      eventId,
      eventData,
      start,
      end,
      labelGroups,
      initialLabels,
    },
    { sounds: { allLabels, timezoneOffset, labelCategories }, placements }
  ) => {
    return {
      modalType,
      eventType,
      eventId: eventData
        ? eventData[0]
          ? eventData[0].type.endsWith("_end")
            ? eventData![0]!.relatedEvent!.id
            : eventId
          : eventId
        : null,
      start,
      end,
      labelGroups,
      initialLabels,
      allLabels,
      labelCategories,
      timezoneOffset,
      placements,
    };
  }
);

export const Labels = React.memo(() => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });

  const {
    eventId,
    start,
    end,
    labelGroups,
    allLabels,
    placements,
    labelCategories,
  } = useSelector(selector);

  const onChange = useCallback(
    (
      datetime: Date | null,
      group: LabelGroupModalState,
      type: ELabelDateType
    ) => {
      if (!validateLabelPickers(datetime, group, type)) {
        return enqueueSnackbar(t(`machineDetail.editEvent.${type}.error`), {
          variant: "error",
        });
      }
      const groupIndex = labelGroups.indexOf(group);
      const newLabelGroup = cloneDeep(labelGroups);
      newLabelGroup[groupIndex][type] = datetime?.toISOString() ?? null;
      dispatch(actions.setLabels({ labelGroups: [...newLabelGroup] }));
    },
    [labelGroups, dispatch, t]
  );

  const addLabelGroup: any = () => {
    dispatch(
      actions.setLabels({
        labelGroups: [
          ...labelGroups,
          {
            labels: [],
            start: start,
            end: end || start,
            toRemoveLabels: [],
            eventId,
            placements: [],
          },
        ],
      })
    );
  };

  const handleLabelSelection: any = (
    group: LabelGroupModalState,
    selectedSubcategories: number[]
  ) => {
    const groupIndex: number = labelGroups.indexOf(group);

    const usedSubcategories = labelGroups[groupIndex].labels.map(
      (label: any) => label.subcategory
    );
    const removedSubcategories = usedSubcategories.filter(
      (id: number) => !selectedSubcategories.includes(id)
    );
    const addedSubcategories = selectedSubcategories.filter(
      (subcategory: number) => !usedSubcategories.includes(subcategory)
    );

    const newLabelGroup = cloneDeep(labelGroups);

    newLabelGroup[groupIndex].toRemoveLabels = removedSubcategories.map(
      (subCat: any) => {
        const label = newLabelGroup[groupIndex].labels.find(
          (item: any) => item.subcategory === subCat
        );
        return label?.id ?? 0;
      }
    );

    newLabelGroup[groupIndex].labels = newLabelGroup[groupIndex].labels.filter(
      (label: LabelModalState) =>
        selectedSubcategories.includes(label.subcategory)
    );

    newLabelGroup[groupIndex].labels.push(
      ...addedSubcategories.map((subcategory: number) => ({
        id: null,
        subcategory,
        description: allLabels.find(
          (label: any) => label.subcategory === subcategory
        ).name,
      }))
    );
    dispatch(actions.setLabels({ labelGroups: [...newLabelGroup] }));
  };

  const handlePlacementSelection: any = (
    group: LabelGroupModalState,
    selectedPlacements: number[]
  ) => {
    const groupIndex: number = labelGroups.indexOf(group);

    const usedPlacements = labelGroups[groupIndex].placements;

    const removedPlacements = usedPlacements.filter(
      (id: number) => !selectedPlacements.includes(id)
    );
    const addedPlacements = selectedPlacements.filter(
      (placement: number) => !usedPlacements.includes(placement)
    );

    const newLabelGroup = cloneDeep(labelGroups);

    newLabelGroup[groupIndex].toRemovePlacements = removedPlacements.map(
      (subCat: any) => {
        const placement = newLabelGroup[groupIndex].placements.find(
          (item: any) => item === subCat
        );
        return placement ?? 0;
      }
    );

    newLabelGroup[groupIndex].placements = newLabelGroup[
      groupIndex
    ].placements.filter((placement: any) =>
      selectedPlacements.includes(placement)
    );

    newLabelGroup[groupIndex].placements.push(...addedPlacements);
    dispatch(actions.setLabels({ labelGroups: [...newLabelGroup] }));
  };

  useQuery(
    eventId ? [eventLabelsKey, { eventId }] : false,
    () => {
      return getEventLabels({
        ids: [eventId!],
      });
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data: any) => {
        dispatch(
          actions.setInitialLabels({
            initialLabels: mapLabelGroups(data.results, eventId),
          })
        );
      },
    }
  );

  useEffect(() => {
    dispatch(fetchStaticData());
  }, [dispatch]);

  const placementsOptions =
    placements.placements?.results?.map((placement: IPlacementList) => ({
      value: placement.id,
      text: placement.customName ?? placement.name,
    })) || [];

  return (
    <Box mb={3}>
      <Divider line>{t("eventModal.titles.labels")}</Divider>
      {labelGroups.map((group, index: number) => {
        if (group.toDelete) {
          return null;
        }
        return (
          <Box
            key={index}
            style={{
              backgroundColor: group.toDelete ? "red" : "transparent",
              width: upMd ? "100%" : "80%",
            }}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Box my={2}>
                  <SearchMultiSelect
                    initialValue={group.labels.map(
                      (label: any) => label.subcategory
                    )}
                    multiple={false}
                    searchPlaceholder={t("eventModal.titles.searchLabels")}
                    id={`${group.start}-${group.end}-${index}`}
                    name="Label select"
                    label={t("eventModal.titles.labels")}
                    confirmButtonLabel={t("Confirm")}
                    deselectButtonLabel={t("filter.deselectAll")}
                    options={
                      allLabels && allLabels.length > 0
                        ? allLabels.map((label: any) => {
                            return {
                              text: label.name,
                              value: label.subcategory,
                              group: label.category,
                            };
                          })
                        : []
                    }
                    groups={
                      labelCategories && labelCategories.length > 0
                        ? labelCategories.map(
                            ({ id, name }: { id: number; name: string }) => ({
                              id,
                              name,
                            })
                          )
                        : []
                    }
                    loading={false}
                    disabled={false}
                    onChangeSubmit={(selectedValues: any) => {
                      handleLabelSelection(group, selectedValues);
                    }}
                    bigSize
                  />
                </Box>
                <Box my={2}>
                  <SearchMultiSelect
                    initialValue={group.placements}
                    multiple={true}
                    searchPlaceholder={t("eventModal.titles.searchPlacements")}
                    id={`${group.start}-${group.end}-${index}`}
                    name="Label select"
                    label={t("eventModal.titles.placements")}
                    confirmButtonLabel={t("Confirm")}
                    deselectButtonLabel={t("filter.deselectAll")}
                    options={placementsOptions}
                    loading={false}
                    disabled={false}
                    onChangeSubmit={(selectedValues: any) => {
                      handlePlacementSelection(group, selectedValues);
                    }}
                    bigSize
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  my={2}
                  sx={{
                    maxWidth: "244px",
                    "& .MuiInputBase-root": {
                      paddingRight: "15px",
                    },
                    "& .css-176aigl-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        height: "0.65rem",
                      },
                  }}
                >
                  <Picker
                    Component={CustomPicker}
                    value={group.start ? dayjs(group.start).toDate() : null}
                    onChange={(datetime: any) =>
                      onChange(datetime, group, ELabelDateType.start)
                    }
                    id="labelsFrom"
                    label={t("machineDetail.editEvent.labels.from")}
                    showTimeSelect={true}
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    maxDate={dayjs(group.end).toDate() || undefined}
                    disableOpenPicker
                    dateWithFormatting
                  />
                </Box>
                <Box
                  my={2}
                  sx={{
                    maxWidth: "244px",
                    "& .MuiInputBase-root": {
                      paddingRight: "15px",
                    },
                    "& .css-176aigl-MuiInputBase-input-MuiOutlinedInput-input":
                      {
                        height: "0.65rem",
                      },
                  }}
                >
                  <Picker
                    Component={CustomPicker}
                    dateFormat="dd/MM/yyyy HH:mm:ss"
                    label={t("machineDetail.editEvent.labels.to")}
                    value={group.end ? dayjs(group.end).toDate() : null}
                    onChange={(datetime: any) =>
                      onChange(datetime, group, ELabelDateType.end)
                    }
                    id="labelsTo"
                    showTimeSelect={true}
                    minDate={dayjs(group.start).toDate() || undefined}
                    disableOpenPicker
                  />
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Button
                  data-cy="removeLabelButton"
                  variant="contained"
                  style={{ backgroundColor: "#e0e0e0", color: "#000" }}
                  onClick={() => {
                    dispatch(actions.tagGroupForDelete({ index }));
                  }}
                >
                  {t("machineDetail.editEvent.labels.remove")}
                </Button>
              </Grid>
            </Grid>
            <Divider />
          </Box>
        );
      })}
      <Box my={2}>
        <Button
          data-cy="addLabelButton"
          variant="contained"
          onClick={addLabelGroup}
          style={{ backgroundColor: "#e0e0e0", color: "#000" }}
        >
          {t("machineDetail.editEvent.labels.add")}
        </Button>
      </Box>
    </Box>
  );
});
