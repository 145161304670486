import React, {
  useState,
  memo,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import Selection from "./Selection";
import Measure from "react-measure";
import { getAvailableRanges } from "api/handlers/dataLabeling/getAvailableRanges";
import * as utils from "./utils";
import Axis from "./Axis";
import Regions from "./Regions";
import Progress from "./Progress";
import { urlFriendlyTZ } from "shared/helpers";
import isEqual from "react-fast-compare";

const ZoomOutView = memo((props) => {
  const {
    windowStart,
    windowEnd,
    machine,
    margin,
    canvasHeight,
    offset,
    regions,
    selectedRegion,
    onSelectRegion,
    onDragRegion,
    aboveZoomOut,
    placement,
    timezoneOffset,
    allLabels,
    editMode,
  } = props;

  const [wrapperWidth, setWrapperWidth] = useState(-1);
  const [audioRegions, setAudioRegions] = useState(regions);
  const [availableRanges, setAvailableRanges] = useState([]);
  const [toggle, setToggle] = useState(0);
  const [selecting, setSelecting] = useState(false);
  const canvasRef = useRef();
  const eventRef = useRef();

  const getPxPerSec = (props, wrapperWidth) => {
    let { windowStart, windowEnd } = props;
    return wrapperWidth / (windowEnd - windowStart);
  };

  let onResize = useCallback(({ bounds }) => setWrapperWidth(bounds.width), []);

  const drawRange = (windowStart, rangeStart, rangeEnd, pxPerSec, ctx) => {
    ctx.fillStyle = "#a61d3c";
    ctx.fillRect(
      (rangeStart - windowStart) * pxPerSec, // start delay
      10, // marginTop
      (rangeEnd - rangeStart) * pxPerSec, // length
      110 // width
    );
  };
  const handleMouseUp = useCallback(
    (e) => {
      if (selecting) return;
      const x = e.clientX;
      const targetStart = e.currentTarget.getBoundingClientRect().left;
      setToggle(x - targetStart);
    },
    [selecting]
  );

  const { selectionStart, selectionEnd, selectionLabel } = useMemo(() => {
    let { selectionStart, selectionEnd } = props;
    let selectionLabel = null;

    if (selectionStart !== null && selectionEnd !== null) {
      let { windowStart: selectionWindowStart, windowEnd: selectionWindowEnd } =
        utils.calculateSelectionWindow(
          props,
          selectionStart,
          selectionEnd,
          getPxPerSec(props, wrapperWidth)
        );

      selectionLabel = `${utils.roundToString(
        Math.abs(selectionWindowEnd - selectionWindowStart),
        2
      )}s`;
    }

    return { selectionStart, selectionEnd, selectionLabel };
  }, [props, wrapperWidth]);

  //drawing audio ranges on every render
  useEffect(() => {
    // drawing audio ranges

    if (availableRanges.length && wrapperWidth > 0 && canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d");
      const pxPerSec = getPxPerSec({ windowStart, windowEnd }, wrapperWidth);

      for (let i = 0; i < availableRanges.length; i++) {
        const { start, end } = availableRanges[i];
        drawRange(
          windowStart,
          new Date(start).valueOf() * 0.001,
          new Date(end).valueOf() * 0.001,
          pxPerSec,
          ctx
        );
      }
    }
  }, [availableRanges, windowStart, windowEnd, wrapperWidth]);

  useEffect(() => {
    const pxPerSec = getPxPerSec(props, wrapperWidth);
    setToggle(offset * pxPerSec);

    if (props.onResizeZoomOut && eventRef.current instanceof HTMLElement) {
      const rect = eventRef.current.getBoundingClientRect();
      props.onResizeZoomOut(rect);
    }
  }, [offset, props, wrapperWidth]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
      });
      if (eventRef?.current) {
        const bodyRect = document.body.getBoundingClientRect();
        const { left, width, height, top } =
          eventRef.current.getBoundingClientRect();
        props.onResizeZoomOut({
          left,
          width,
          height,
          top: top - bodyRect.top,
        });
      }
    });

    resizeObserver.observe(document.body);
  }, [eventRef, offset, props, wrapperWidth]);

  useEffect(() => {
    setAudioRegions(regions);
  }, [regions]);

  // // comonentDidMount
  useEffect(() => {
    // fetching available audio for given time period
    const getRanges = async () => {
      let ranges = [];
      if (machine && placement.value && windowStart && windowEnd) {
        ranges = await getAvailableRanges(
          machine,
          windowStart,
          windowEnd,
          placement.value,
          urlFriendlyTZ(false, timezoneOffset)
        );
      }

      setAvailableRanges(ranges);
    };
    getRanges();
  }, [machine, placement, windowEnd, windowStart, timezoneOffset]);

  const newRegions = audioRegions.map((region) => {
    const labels = region.labels.map((label) => {
      const newLabel = allLabels.find(
        (item) => item.subcategory === label.subcategory
      );
      return {
        ...label,
        color: newLabel?.color || null,
      };
    });
    return {
      ...region,
      labels: labels,
    };
  });

  return (
    <Measure bounds onResize={onResize}>
      {({ measureRef }) => {
        return (
          <div
            className="wave-wrapper"
            style={{ position: "relative" }}
            onMouseUp={handleMouseUp}
            onMouseDown={() => setSelecting(false)}
            onMouseMove={() => setSelecting(true)}
          >
            <div
              className="wave-canvas-wrapper"
              ref={measureRef}
              style={{
                height: canvasHeight,
                marginTop: margin,
                marginLeft: margin,
                marginRight: margin,
              }}
            >
              <React.Fragment>
                <canvas
                  ref={canvasRef}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: canvasHeight,
                    width: wrapperWidth,
                  }}
                  width={wrapperWidth}
                  height={256}
                />
                <Progress width={toggle} />
              </React.Fragment>
            </div>
            {wrapperWidth > 0 && (
              <Axis
                width={wrapperWidth}
                windowStart={windowStart || 0}
                windowEnd={windowEnd || 0}
              />
            )}
            {wrapperWidth > 0 && (
              <div
                ref={eventRef}
                style={{
                  position: "absolute",
                  top: margin,
                  left: margin,
                  right: margin,
                  height: canvasHeight + 20,
                  zIndex: 1000,
                  opacity: 0.7,
                }}
              >
                <Regions
                  windowStart={windowStart}
                  regions={newRegions}
                  height={canvasHeight}
                  bottom={20}
                  selectedRegion={selectedRegion}
                  onSelectRegion={onSelectRegion}
                  onDragRegion={onDragRegion}
                  pxPerSec={getPxPerSec(
                    { windowStart, windowEnd },
                    wrapperWidth
                  )}
                  editMode={editMode}
                  hourView
                />
                {editMode && selectionStart && selectionEnd && aboveZoomOut && (
                  <Selection
                    start={selectionStart}
                    end={selectionEnd}
                    label={selectionLabel}
                    color="green"
                    textColor="white"
                  />
                )}
              </div>
            )}
          </div>
        );
      }}
    </Measure>
  );
}, isEqual);
export default ZoomOutView;
