import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

type Props = {
  placement?: number[];
};
export async function getLabels({ placement }: Props) {
  const url = endpoints.labels.default;
  const params = {
    placement,
  };
  try {
    const {
      data: { results },
    } = await axiosNeuron.get(url, {
      params,
    });
    return results;
  } catch {
    return;
  }
}
