import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";

import { EActions, IActionTypes } from "./types";
import { AppState } from "store";
import { prepareActions } from "store/helpers";
import getCustomClassifierModelCategoryInfo from "api/handlers/eventSounds/getCustomClassifierModelCategoryInfo";
import { ICustomModelCategoryInfo } from "types/customModelCategory";

export const path = "customModelCategory";

const actionData = [
  [EActions.setModelCategory, "modelCategory"],

  [EActions.fetchCustomClassifierModelCategoryInfoRequest],
  [EActions.fetchCustomClassifierModelCategoryInfoFail, "error"],
  [
    EActions.fetchCustomClassifierModelCategoryInfoSuccess,
    "customClassifierModelCategoryInfo",
  ],
  [EActions.setModelUrls, "modelUrls"],
  [EActions.setNominalClasses, "nominalClasses"],
  [EActions.setRegressionTargets, "regressionTargets"],
  [EActions.setTraceId, "traceId"],
  [EActions.setCustomName, "customName"],
];

const actions = prepareActions<IActionTypes, EActions>(actionData, path);

export const fetchCustomClassifierModelCategoryInfo =
  (modelCategory: string) =>
  async (dispatch: ThunkDispatch<AppState, void, Action> | any | any) => {
    dispatch(actions[EActions.fetchCustomClassifierModelCategoryInfoRequest]());
    try {
      const modelUrls =
        await getCustomClassifierModelCategoryInfo(modelCategory);
      const filenames = modelUrls?.results?.map(
        (cat: ICustomModelCategoryInfo) => cat.filename
      );

      dispatch(actions[EActions.setModelUrls](filenames));
      dispatch(
        actions[EActions.fetchCustomClassifierModelCategoryInfoSuccess](
          modelUrls
        )
      );
    } catch (error) {
      dispatch(
        actions[EActions.fetchCustomClassifierModelCategoryInfoFail](error)
      );
    }
  };

export default actions;
