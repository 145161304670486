import { Box, Button } from "@mui/material";
import Checkbox from "components/checkbox";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ActionButton from "components/button";
import { isEqual, orderBy, uniqBy } from "lodash";
import { useDispatch } from "react-redux";
import actions, {
  loadPlacementBufferSilent,
} from "store/machineDetail/sounds/actions";
import { useSelector } from "react-redux";
import { AppState } from "store";
import Heading4 from "components/typography/heading/heading4";
import Divider from "components/typography/heading/divider";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const {
  setIsPreviousClicked,
  setIsNextClicked,
  setCheckedLabels,
  setIsChecked,
  setShowHiddenLabels,
  setIsCombinationOnly,
} = actions;

const LabelSwitch = ({
  labelsData,
  onSelectRegion,
  selectedRegion,
  play,
  allLabels,
  labels,
  duration,
  placement,
  windowStart,
  windowEnd,
  regions,
  updateRegion,
  setQuery,
}: any) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();
  const {
    isPreviousClicked,
    isNextClicked,
    checkedLabels,
    isChecked,
    isHiddenLabelShown,
    isCombinationOnly,
  } = useSelector((state: AppState) => state.machineDetail.sounds, isEqual);

  const [isUncollapsed, setIsUncollapsed] = useState(false);

  const onPreviousClick = (e: any) => {
    if (labelsData?.length) {
      if (selectedRegion) {
        const selectedIndex = labelsData.findIndex(
          (label: any) => label.start === selectedRegion.start
        );

        if (selectedIndex) {
          const region = labelsData[selectedIndex - 1];
          onSelectRegion(region, e);
          dispatch(setIsPreviousClicked(false));
        } else {
          dispatch(setIsPreviousClicked(true));
          const start = selectedRegion.start - duration;
          dispatch(
            loadPlacementBufferSilent(
              start,
              0,
              placement.value,
              selectedRegion.ids[0],
              undefined,
              setQuery
            )
          );
        }
      } else {
        onSelectRegion(labelsData?.[0], e);
      }
    } else {
      dispatch(setIsPreviousClicked(true));
      const start = windowStart - duration;
      dispatch(
        loadPlacementBufferSilent(
          start,
          0,
          placement.value,
          true,
          undefined,
          setQuery
        )
      );
    }
  };

  const onNextClick = (e: any) => {
    if (labelsData?.length) {
      if (selectedRegion) {
        const selectedIndex = labelsData.findIndex(
          (label: any) => label.start === selectedRegion.start
        );

        if (selectedIndex !== labelsData.length - 1) {
          const region = labelsData[selectedIndex + 1];
          onSelectRegion(region, e);
          dispatch(setIsNextClicked(false));
        } else {
          const start = selectedRegion.start;
          dispatch(setIsNextClicked(true));
          dispatch(
            loadPlacementBufferSilent(
              start,
              0,
              placement.value,
              undefined,
              selectedRegion.ids[0],
              setQuery
            ) as any
          );
        }
      } else {
        onSelectRegion(labelsData?.[0], e);
      }
    } else {
      dispatch(setIsNextClicked(true));
      dispatch(
        loadPlacementBufferSilent(
          windowEnd,
          0,
          placement.value,
          undefined,
          true,
          setQuery
        ) as any
      );
    }
  };

  useEffect(() => {
    if (labelsData) {
      if (isPreviousClicked) {
        dispatch(setIsPreviousClicked(false));
        onSelectRegion(labelsData?.[labelsData?.length - 1]);
      }
      if (isNextClicked) {
        dispatch(setIsNextClicked(false));
        onSelectRegion(labelsData?.[0]);
      }
    }
  }, [
    labelsData,
    isPreviousClicked,
    isNextClicked,
    setIsPreviousClicked,
    setIsNextClicked,
  ]);

  const processLabels = () => {
    let selected: any = {};
    for (let label of labels) {
      selected[label.name] = true;
      label.color = allLabels.find(
        (item: any) => item.subcategory === label.subcategory
      )?.color;
    }
    let newLabels = [];
    newLabels = uniqBy([...labels, ...allLabels], (item) => item.name);
    newLabels = orderBy(newLabels, ["name"], ["asc"]);
    return { newLabels, selected };
  };

  const { newLabels: data, selected } = processLabels();

  const modifiedData = data?.filter(({ name }: any) => name?.[0] !== "$");

  useEffect(() => {
    if (isCombinationOnly) {
      const filteredRegions = regions.filter((item: any) => {
        const isWithinRange =
          item.start >= windowStart && item.end <= windowEnd;

        if (!isWithinRange) {
          return true;
        }
        const matchingLabels = item.labels.filter((label: any) =>
          checkedLabels.some(
            (keepLabel: any) => keepLabel.subcategory === label.subcategory
          )
        );

        if (checkedLabels.length > 1) {
          return matchingLabels.length > 1;
        } else {
          return matchingLabels.length === item.labels.length;
        }
      });
      updateRegion(filteredRegions);
    } else {
      if (isChecked) {
        const filteredRegions = regions.filter((item: any) => {
          const isWithinRange =
            item.start >= windowStart && item.end <= windowEnd;

          if (!isWithinRange) {
            return true;
          }
          const hasMatchingLabel = item.labels.some((label: any) =>
            checkedLabels.some(
              (keepLabel: any) => keepLabel.subcategory === label.subcategory
            )
          );

          return hasMatchingLabel;
        });
        updateRegion(filteredRegions);
      }
    }
  }, [checkedLabels, isChecked, isCombinationOnly, regions]);

  return (
    <Box maxWidth="17rem" pl={1}>
      <Heading4
        onClick={() => {
          setIsUncollapsed(!isUncollapsed);
        }}
        sx={{
          cursor: "pointer",
          width: "17rem",
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
        pl={1}
      >
        {t("filterLabel.title")}
        {isUncollapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </Heading4>
      {isUncollapsed ? (
        <Box
          sx={{
            maxHeight: "21rem",
            overflowY: "scroll",
            overflowX: "auto",
            borderWidth: 1,
            borderColor: "#bdbdbd",
            borderStyle: "solid",
          }}
          pt={1}
          pr={1}
        >
          <Box>
            <Checkbox
              label={t("filterLabel.combination")}
              onChange={(e) => {
                dispatch(setIsCombinationOnly(!isCombinationOnly));
                dispatch(
                  loadPlacementBufferSilent(windowStart, 0, placement.value)
                );
              }}
              checked={isCombinationOnly}
              fontWeight="normal"
            />
            <Divider line noMargin />
          </Box>

          {modifiedData.map((item: any) => {
            const name = item.name;
            const isChecked = checkedLabels.some(
              (label: any) => label.subcategory === item.subcategory
            );

            const color = selected[name] ? item.color : "initial";

            return (
              <Box>
                <Checkbox
                  label={name}
                  bgColor={
                    color !== "initial" ? (color ? `#${color}` : "green") : null
                  }
                  onChange={(e) => {
                    dispatch(setIsChecked(true));
                    if (isChecked) {
                      dispatch(
                        setCheckedLabels(
                          checkedLabels.filter(
                            (label: any) =>
                              label.subcategory !== item.subcategory
                          )
                        )
                      );
                    } else {
                      dispatch(
                        setCheckedLabels([
                          ...checkedLabels,
                          {
                            name: item.name,
                            subcategory: item.subcategory,
                          },
                        ])
                      );
                    }
                    dispatch(
                      loadPlacementBufferSilent(windowStart, 0, placement.value)
                    );
                  }}
                  checked={isChecked}
                />
              </Box>
            );
          })}
          <Checkbox
            label={t("filterLabel.showHidden")}
            onChange={(e) => {
              dispatch(setShowHiddenLabels(!isHiddenLabelShown));
            }}
            checked={isHiddenLabelShown}
            fontWeight="normal"
          />
          {isHiddenLabelShown
            ? data
                .filter(({ name }: any) => name?.[0] === "$")
                .map((item: any) => {
                  const name = item.name;
                  const isChecked = checkedLabels.some(
                    (label: any) => label.subcategory === item.subcategory
                  );

                  const color = selected[name] ? item.color : "initial";

                  return (
                    <Box>
                      <Checkbox
                        label={name && name?.[0] === "$" ? name.slice(1) : name}
                        bgColor={
                          color !== "initial" ? item.color || "green" : null
                        }
                        onChange={(e) => {
                          dispatch(setIsChecked(true));
                          if (isChecked) {
                            dispatch(
                              setCheckedLabels(
                                checkedLabels.filter(
                                  (label: any) =>
                                    label.subcategory !== item.subcategory
                                )
                              )
                            );
                          } else {
                            dispatch(
                              setCheckedLabels([
                                ...checkedLabels,
                                {
                                  name: item.name,
                                  subcategory: item.subcategory,
                                },
                              ])
                            );
                          }
                          dispatch(
                            loadPlacementBufferSilent(
                              windowStart,
                              0,
                              placement.value
                            )
                          );
                        }}
                        checked={isChecked}
                      />
                    </Box>
                  );
                })
            : null}
          <Box display="flex">
            <ActionButton
              onClick={() => {
                const newData = isHiddenLabelShown
                  ? [...modifiedData, ...data]
                  : modifiedData;
                dispatch(
                  setCheckedLabels(
                    newData.map((item: any) => ({
                      name: item.name,
                      subcategory: item.subcategory,
                    }))
                  )
                );
              }}
            >
              {t("filterLabel.selectAll")}
            </ActionButton>
            <ActionButton onClick={() => dispatch(setCheckedLabels([]))}>
              {t("filterLabel.deselectAll")}
            </ActionButton>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <Box mb={1} mt={2.5} display="flex" gap={1} flexWrap="wrap">
        <Button
          style={{ maxWidth: "5rem", lineHeight: 1 }}
          variant="contained"
          onClick={onPreviousClick}
        >
          <span>{t("taggingapp.button.filterLabel.previous")}</span>
        </Button>
        <Button
          variant="contained"
          onClick={onNextClick}
          style={{ maxWidth: "5rem", lineHeight: 1 }}
        >
          <span>{t("taggingapp.button.filterLabel.next")}</span>
        </Button>
        <Button
          data-cy="playButton"
          variant="contained"
          onClick={() => {
            if (selectedRegion) {
              play();
            }
          }}
          style={{ maxWidth: "5rem", lineHeight: 1 }}
        >
          <span>{t("taggingapp.button.filterLabel.play")}</span>
        </Button>
      </Box>
    </Box>
  );
};

export default LabelSwitch;
