import React, { Dispatch, SetStateAction, useMemo } from "react";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AppState } from "store";
import isEqual from "react-fast-compare";
import Picker from "localization/pickerLocale";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { Box, FormControl, InputLabel, MenuItem } from "@mui/material";
import IconButton from "components/iconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "@mui/material/Select";
import { v4 as uuidv4 } from "uuid";
import useData from "dataHooks/machines/placements/detailPlacement";
import Spinner from "components/spinner";
import { useTranslation } from "react-i18next";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import moment from "moment";
import actions from "store/machineDetail/analysisSounds/actions";
import dayjs from "dayjs";
import CustomPicker from "components/customPickers";

export interface AnalysisTimeManagerData {
  dates: { date: string; uuid: string; createdAt?: number }[];
  placement?: number;
  samples?: number;
}

interface Props {
  data: AnalysisTimeManagerData;
  setData: Dispatch<SetStateAction<AnalysisTimeManagerData | null | undefined>>;
}

// negative values mean "between dates"
const samplesOptions = [1, 5, 10, 30, -30, -60, -120, -180, -360, -720, -1440];

export const ANALYSIS_GET_PARAM_SEPARATOR = "|";

export const prepareAnalysisUrl = ({ dates }: AnalysisTimeManagerData) =>
  dates.map(({ date }) => date).join(ANALYSIS_GET_PARAM_SEPARATOR) || undefined;

const InputLabelComponent = (props: TextFieldProps) => {
  return <TextField {...props} size="small" />;
};

type CombinedState = {
  state: AppState;
  placement: number;
};

const selector = createSelector(
  ({ state, placement }: CombinedState) =>
    state.machineDetail.analysisSounds.placements
      ? state.machineDetail.analysisSounds.placements[placement]
      : null,
  ({ state }: CombinedState) => state.machineDetail.analysisSounds,
  (placement, sounds) => {
    return {
      buffer: placement?.buffer ?? {},
      isAnalysed: sounds.isAnalysed,
    };
  }
);

const AnalysisTimeManager: React.FC<Props> = ({ data, setData }) => {
  const { placements, reloading } = useData();
  const [newDateAdded, setNewDateAdded] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const {
    buffer: { selectionDates },
    isAnalysed,
  } = useSelector(
    (state: AppState) => selector({ placement: data.placement || 0, state }),
    isEqual
  );
  const dispatch: any = useDispatch();
  const handleDateDelete = (uuid: string) => {
    setData((prev: any) => {
      if (prev) {
        return {
          ...prev,
          dates: prev.dates.filter((item: any) => item.uuid !== uuid),
        };
      } else {
        return null;
      }
    });
  };
  const handleSampleChange = (value: unknown) => {
    if (typeof value === "number") {
      setData((prev: any) => {
        if (prev) {
          return {
            ...prev,
            samples: value,
          };
        } else {
          return null;
        }
      });
    }
  };
  const handlePlacementChange = (value: unknown) => {
    if (typeof value === "number") {
      setData((prev: any) => {
        if (prev) {
          return {
            ...prev,
            placement: value,
          };
        } else {
          return null;
        }
      });
    }
  };
  const handleDateChange = (uuid: string, date: Date | null) => {
    const itemToChange = data.dates.find((item: any) => item.uuid === uuid);
    if (itemToChange && date) {
      itemToChange.date = dayjs(date).toDate()?.toISOString();
      setData((prev: any) => {
        if (prev) {
          return {
            ...prev,
            dates: prev.dates.map((item: any) =>
              item.uuid !== uuid ? item : itemToChange
            ),
          };
        } else {
          return null;
        }
      });
    }
  };
  const handleAddDate = () => {
    setData((prev: any) => {
      if (prev) {
        return {
          ...prev,
          dates: [
            ...prev.dates,
            {
              date: moment().toISOString(),
              uuid: uuidv4(),
              createdAt: moment().valueOf(),
            },
          ],
        };
      } else {
        return null;
      }
    });
    dispatch(actions.setIsAnalysed(false));
    setNewDateAdded(true);
  };

  React.useEffect(() => {
    if (
      placements &&
      data.placement === undefined &&
      data.samples === undefined
    ) {
      setData((prev: any) => {
        if (prev) {
          return {
            ...prev,
            placement: placements.results[0].id,
            samples: samplesOptions[1],
          };
        } else {
          return null;
        }
      });
    }
  }, [data, placements, setData]);

  const noDataError = useMemo(() => {
    if (data.samples && data.samples < 0) return false;
    if (!isAnalysed && !selectionDates) return false;
    const selectionDateSet = new Set(
      selectionDates?.map((i: Date) => i.getTime())
    );
    const foundDates = data.dates.filter((i: any) =>
      selectionDateSet.has(new Date(i.date).getTime())
    );
    return (
      isAnalysed && (!selectionDates || foundDates.length < data.dates.length)
    );
  }, [data, selectionDates, isAnalysed]);

  const moveFn = (uuid: string, uuids: { [key: string]: string }) => () => {
    const allUuids = data.dates.map((i: any) => i.uuid);
    const oldIndex = allUuids.indexOf(uuid);
    const newIndex = allUuids.indexOf(uuids[uuid]);
    const old = data.dates[oldIndex];
    data.dates[oldIndex] = data.dates[newIndex];
    data.dates[newIndex] = old;
    setData((prev: any) => ({
      ...prev,
      dates: data.dates,
    }));
  };

  const previousUuids = useMemo(() => {
    const ret: { [key: string]: string } = {};
    if (!data || !data.dates) return ret;
    for (var i = 1; i < data.dates.length; ++i) {
      ret[data.dates[i].uuid] = data.dates[i - 1].uuid;
    }
    return ret;
  }, [data]);

  const nextUuids = useMemo(() => {
    const ret: { [key: string]: string } = {};
    if (!data || !data.dates) return ret;
    for (var i = 0; i < data.dates.length - 1; ++i) {
      ret[data.dates[i].uuid] = data.dates[i + 1].uuid;
    }
    return ret;
  }, [data]);

  if (!data) {
    return null;
  }

  if (
    !placements ||
    reloading ||
    data.placement === undefined ||
    data.samples === undefined
  ) {
    return <Spinner />;
  }

  const DateComponent = ({
    uuid,
    date,
    initialOpened,
    moveUp,
    moveDown,
  }: {
    uuid: string;
    date: string;
    initialOpened?: boolean;
    moveUp?: any;
    moveDown?: any;
  }) => {
    const [hasError, setHasError] = React.useState<boolean>(false);
    const [newDate, setNewDate] = React.useState<any>(date);

    const noDataError = useMemo(() => {
      if (data.samples && data.samples < 0) return false;
      if (!isAnalysed && !selectionDates) return false;
      return (
        isAnalysed &&
        (!selectionDates ||
          selectionDates
            .map((i: Date) => i.getTime())
            .indexOf(new Date(date).getTime()) === -1)
      );
    }, [date]);
    return (
      <Box display="flex" mb={2} alignItems="center" key={uuid}>
        <Box
          sx={{
            "& .MuiInputBase-root": {
              paddingRight: "15px",
            },
          }}
        >
          <CustomPicker
            label={t("analysisTimeManager.date")}
            value={newDate ? dayjs(newDate).toDate() : null}
            onChange={(date: any) => {
              setNewDate(date);
            }}
            format="YYYY/MM/DD HH:mm:ss"
            disableFuture
            {...(initialOpened ? { open: initialOpened } : undefined)}
            onClose={() => {
              handleDateChange(uuid, newDate);
              if (initialOpened) {
                setNewDateAdded(false);
              }
            }}
            id={`date_${uuid}`}
            dateFormat="yyyy/MM/dd HH:mm:ss"
            hasError={hasError || noDataError}
            setHasError={setHasError}
            showTimeSelect
            isOpenOnSelect
          />
        </Box>
        <Box ml={1} display="flex">
          <Box>
            <IconButton
              size="small"
              color="primary"
              onClick={moveUp}
              style={{
                opacity: moveUp ? 1 : 0,
                display: !moveUp ? "none" : "block",
              }}
              disabled={!moveUp}
            >
              <KeyboardArrowUpIcon />
            </IconButton>
            <IconButton
              size="small"
              color="primary"
              onClick={moveDown}
              style={{
                opacity: moveDown ? 1 : 0,
                display: !moveDown ? "none" : "block",
              }}
              disabled={!moveDown}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </Box>
          <IconButton
            size="small"
            color="primary"
            onClick={() => handleDateDelete(uuid)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  return (
    <Box py={2}>
      <Box ml={0} mb={2}>
        {placements && (
          <FormControl variant="outlined" fullWidth={true}>
            <InputLabel id="placement">
              {t("analysisTimeManager.placement")}
            </InputLabel>
            <Select
              id="placement"
              labelId="placement"
              label={t("analysisTimeManager.placement")}
              value={data.placement}
              onChange={(e: any) => handlePlacementChange(e.target.value)}
            >
              {placements.results.map((option: any) => {
                return (
                  <MenuItem value={option?.id} key={option?.id}>
                    {option.customName || option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        )}
      </Box>
      <Box ml={0} mb={2}>
        <FormControl variant="outlined" fullWidth={true}>
          <InputLabel id="placement">
            {t("analysisTimeManager.numberOfSamples")}
          </InputLabel>
          <Select
            variant="outlined"
            labelId="placement"
            label={t("analysisTimeManager.numberOfSamples")}
            value={data.samples}
            onChange={(e: any) => handleSampleChange(e.target.value)}
          >
            {samplesOptions.map((option: any) => {
              return (
                <MenuItem value={option} key={option}>
                  {t(
                    option < 0
                      ? "analysisTimeManager.nSamplesBetweenDates"
                      : "analysisTimeManager.nSamplesForEachDate",
                    { n: Math.abs(option) }
                  )}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      {data.dates.map(({ uuid, date }, i) => (
        <DateComponent
          key={uuid}
          uuid={uuid}
          date={date}
          moveUp={previousUuids[uuid] && moveFn(uuid, previousUuids)}
          moveDown={nextUuids[uuid] && moveFn(uuid, nextUuids)}
          initialOpened={newDateAdded && i === data.dates.length - 1}
        />
      ))}
      {data.dates.length < 50 && (
        <div>
          <Box my={1}>
            <IconButton
              size="small"
              color="primary"
              onClick={() => handleAddDate()}
            >
              <AddCircleIcon />
            </IconButton>
            <span>{t("analysisTimeManager.addDate")}</span>
          </Box>
        </div>
      )}
      {noDataError && (
        <Box>
          <span style={{ color: "red" }}>
            {t("analysisTimeManager.noDataError")}
          </span>
        </Box>
      )}
    </Box>
  );
};

export default AnalysisTimeManager;
