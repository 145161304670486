import React, { memo, useCallback } from "react";
import Picker from "localization/pickerLocale";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useDispatch } from "react-redux";
import actions from "store/projectDetail/temporaryToken/actions";
import { makeStyles } from "@mui/styles";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CustomPicker from "components/customPickers";

interface IStartDatePicker {
  date: string | undefined;
  type: string;
  disabled?: boolean;
  validateRange?: any;
  eventType?: string | null;
  label: string;
}

const useStyles = makeStyles((theme: any) => ({
  input: {
    width: "100%",
  },
  wrapper: {
    "& .MuiInputBase-root": {
      paddingRight: "15px",
    },
  },
}));

export const DateAndTimePicker = memo(
  ({ disabled, type, date, label }: IStartDatePicker) => {
    const classes = useStyles();
    const dispatch: any = useDispatch();

    const onChange = useCallback(
      (date: any) => {
        dispatch(actions.setExpiresAt(date));
      },
      [dispatch, type]
    );

    const today = new Date();
    const nextMonth = new Date(today);
    nextMonth.setMonth(today.getMonth() + 1);

    return (
      <Box className={classes.wrapper}>
        <Picker
          Component={CustomPicker}
          disabled={disabled}
          minDate={dayjs(today).toDate()}
          maxDate={dayjs(nextMonth).toDate()}
          label={label}
          value={date ? dayjs(date).toDate() : null}
          onChange={onChange}
          id={label}
          showTimeSelect={true}
          dateFormat="yyyy/MM/dd HH:mm:ss"
          icon={<AccessTimeIcon />}
          padding="0 4px 0 0"
        />
      </Box>
    );
  }
);
