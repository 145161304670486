import React, { memo } from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { useStyles } from "../styles";

interface ICustomModelHead {
  headerRef: any;
  isTraining: boolean;
}

const CustomModelHead = memo(({ headerRef, isTraining }: ICustomModelHead) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const tt = (i: string) => t(`models.detail.customModels.${i}`);

  return (
    <TableHead ref={headerRef} className={classes.tableHeader}>
      {isTraining ? (
        <TableRow>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          ></TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("customModelCategory")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("datasetName")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("customModelTrainingName")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("datasetId")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("version")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("trainingStarted")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("trainingFinished")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("status")}
          </TableCell>
          <TableCell
            align="center"
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("jobName")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("OtherInfo")}
          </TableCell>
        </TableRow>
      ) : (
        <TableRow>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          ></TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("customModelCategory")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("datasetName")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("customModelTrainingName")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("datasetId")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("modelId")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("version")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("trainedDate")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("status")}
          </TableCell>
          <TableCell
            align="center"
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          >
            {tt("OtherInfo")}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.tdTableHead}`}
          ></TableCell>
        </TableRow>
      )}
    </TableHead>
  );
});

export default CustomModelHead;
