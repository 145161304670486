import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";
import moment from "moment";

type Params = {
  trained_model_id: number;
  from: Date;
  to: Date;
  environment: string;
};

export default async function recomputeResults(machine: number, data: Params) {
  const url = endpoints.machines.recomputeResults(machine);
  try {
    const response = await axiosNeuron.post(url, {
      trained_model_id: data.trained_model_id,
      environment: data.environment,
      start_datetime: moment
        .utc(new Date(data.from))
        .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
      end_datetime: moment
        .utc(new Date(data.to))
        .format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"),
    });
    return response;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
