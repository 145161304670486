import { EActions, IOCTState, TReducers } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

export const initialState: IOCTState = {
  error: null,
  isFetching: false,
  modelCategory: "",

  customClassifierModelCategoryInfo: {
    data: null,
    isFetching: false,
    error: null,
  },
  modelUrls: [],
  nominalClasses: [],
  regressionTargets: [],
  traceId: null,
  customName: null,
};

const reducers: TReducers = {
  [EActions.setModelCategory]: ({ payload: { modelCategory } }) => ({
    modelCategory,
  }),
  [EActions.fetchCustomClassifierModelCategoryInfoRequest]: () => ({
    customClassifierModelCategoryInfo: {
      data: null,
      isFetching: true,
      error: null,
    },
  }),
  [EActions.fetchCustomClassifierModelCategoryInfoFail]: ({
    payload: { error },
  }) => ({
    customClassifierModelCategoryInfo: {
      data: null,
      isFetching: false,
      error,
    },
  }),
  [EActions.fetchCustomClassifierModelCategoryInfoSuccess]: ({
    payload: { customClassifierModelCategoryInfo },
  }) => ({
    customClassifierModelCategoryInfo: {
      data: customClassifierModelCategoryInfo,
      isFetching: false,
      error: null,
    },
  }),
  [EActions.setModelUrls]: ({ payload: { modelUrls } }) => ({
    modelUrls,
  }),
  [EActions.setNominalClasses]: ({ payload: { nominalClasses } }) => ({
    nominalClasses,
  }),
  [EActions.setRegressionTargets]: ({ payload: { regressionTargets } }) => ({
    regressionTargets,
  }),
  [EActions.setTraceId]: ({ payload: { traceId } }) => ({
    traceId,
  }),
  [EActions.setCustomName]: ({ payload: { customName } }) => ({
    customName,
  }),
};

export default createReducer<IOCTState>(path, initialState, reducers);
