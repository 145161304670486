export enum EActions {
  setCommonData = "setCommonData",
  setZoom = "setZoom",
  setLoadingBuffer = "setLoadingBuffer",
  setBufferLoaded = "setBufferLoaded",
  setLoadingModelResults = "setLoadingModelResults",
  setModelResultsLoaded = "setModelResultsLoaded",
  setLoadingCommonData = "setLoadingCommonData",
  changeTimezone = "changeTimezone",
  setReset = "setReset",
  setBulkZoom = "setBulkZoom",
  setPlaying = "setPlaying",
  setLargeDownloadAllowed = "setLargeDownloadAllowed",
  setVolume = "setVolume",
  setIsAnalysed = "setIsAnalysed",

  setLabelLoading = "setLabelLoading",
  setLabelSuccess = "setLabelSuccess",
  setLabelFail = "setLabelFail",
  setLabelValues = "setLabelValues",
  setLabelTimeInterval = "setLabelTimeInterval",
}

export interface ISoundsState {
  isDocumentEventActive: boolean;
  loaded: boolean;
  loadingData: boolean;
  allLabels: any[] | null;
  zoom: number;
  localZoom: number;
  sampleRate: number;
  year?: string;
  yearData: any[] | null;
  date?: string;
  labelsDates: undefined;
  dayData: null;
  time?: string;
  timezoneOffset: string;
  duration: number;
  placements: { [key: string]: any } | undefined;
  bufferStart?: number;
  loadingCommonData: boolean;
  isPlaying: number | null;
  largeDownloadAllowed: boolean;
  isAnalysed: boolean;
  labels: {
    data: any[] | null;
    loading: boolean;
    error: Error | null;
  };

  labelValues:
    | {
        name: string;
        subcategory: number;
      }[]
    | null;

  labelTimeInterval: {
    start: any;
    end: any;
  } | null;
}

interface IActionData {
  data: {
    placements: any[];
    yearData: any[];
    allLabels: any[];
    dayData: any[];
    date: string;
    time: string;
    timezoneOffset: string;
    machine: number;
    duration: number;
  };
  zoom: number;
  loadingBuffer: boolean;
  loading: boolean;
  placement: number;
  buffer: any;
  offsets: any;
  rest: any;
  largeDownloadAllowed: boolean;
  loadingModelResults: boolean;
  modelResults: any[] | null;
  volume: number;
  amplifiedBuffer: any;
  labels: {
    data: any[] | null;
    loading: boolean;
    error: Error | null;
  };
}

export interface IActionTypes {
  type: typeof EActions;
  payload: IActionData;
}

export type TReducers = {
  [key: string]: (p: IActionTypes, s: ISoundsState) => void;
};
