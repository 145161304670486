import { ICustomModelCategoryInfo } from "types/customModelCategory";
import { ResponseType } from "types/response";

export enum EActions {
  setModelCategory = "setModelCategory",
  fetchCustomClassifierModelCategoryInfoRequest = "fetchCustomClassifierModelCategoryInfoRequest",
  fetchCustomClassifierModelCategoryInfoFail = "fetchCustomClassifierModelCategoryInfoFail",
  fetchCustomClassifierModelCategoryInfoSuccess = "fetchCustomClassifierModelCategoryInfoSuccess",
  setModelUrls = "setModelUrls",
  setNominalClasses = "setNominalClasses",
  setRegressionTargets = "setRegressionTargets",
  setTraceId = "setTraceId",
  setCustomName = "setCustomName",
}

export interface IRegressionTargets {
  class: number;
  numeric_target: number;
}

export interface IOCTState {
  error: Error | null;
  isFetching: false;

  modelCategory: string;
  customClassifierModelCategoryInfo: {
    data: ResponseType<ICustomModelCategoryInfo> | null;
    isFetching: false;
    error: null;
  };
  modelUrls: string[];

  nominalClasses: number[];
  regressionTargets: IRegressionTargets[];
  traceId: string | null;
  customName: string | null;
}

export interface IActionTypes {
  type: typeof EActions;
  payload: IOCTState;
}

export type TReducers = {
  [key: string]: (p: IActionTypes, s: IOCTState) => void;
};
