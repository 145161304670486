import React, { useCallback, useMemo, useState } from "react";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { IconButton, MenuItem, Table } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {
  InfoRounded,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { useStyles } from "../styles";
import moment from "moment-timezone";
import TooltipComponent from "components/tooltip";
import { KebabMenu } from "components/kebab";
import ConfirmPopup from "components/confirmPopup";
import archiveModel from "api/handlers/machine/archiveModel";
import { useSnackbar } from "notistack";
import CustomModelDetailHead from "./tableHeadDetail";
import { ICustomModel } from "types/customModels";
import CustomModelDetailBodyDeployments from "./tableBodyDetailDeployments";
import unarchiveModel from "api/handlers/machine/unarchiveModel";

const CustomModelBodyDeployment = ({ group }: any) => {
  const { t } = useTranslation();
  const tt = (i: string) => t(`models.detail.customModels.${i}`);
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [isTaskId, setIsTaskId] = useState(false);
  const [openOtherInfoTooltip, setOpenOtherInfoTooltip] = useState(false);
  const [archiveId, setArchiveId] = useState<number | null>(null);
  const [unarchiveId, setUnarchiveId] = useState<number | null>(null);
  const [machineId, setMachineId] = useState<number | null>(null);

  const deploymentData = group.deployments[0];

  const status = deploymentData.deployments.some(
    (dep: any) => dep.is_ready && !dep.is_deleted
  );
  const toolTipInnerInfo = (
    <>
      <div>
        <strong>{tt("trainedModel")}</strong>:{" "}
        <span>{deploymentData.trained_model}</span>
      </div>
      <div>
        <strong>{tt("modelTypeName")}</strong>:{" "}
        <span>{deploymentData.model_type_name}</span>
      </div>
      <div>
        <strong>{tt("taskId")}</strong>: <span>{deploymentData.task_id}</span>
      </div>
      <div>
        <strong>{tt("taskName")}</strong>:{" "}
        <span>{deploymentData.task_name}</span>
      </div>
      <div>
        <strong>{tt("job")}</strong>: <span>{deploymentData.job_name}</span>
      </div>
    </>
  );

  const handleArchive = async () => {
    try {
      await archiveModel(machineId!, { trained_model_id: archiveId! });
      enqueueSnackbar(tt("archived"));
      setArchiveId(null);
    } catch (e) {
      enqueueSnackbar(tt("errorArchiving"));
    }
    setMachineId(null);
  };

  const handleUnarchive = async () => {
    try {
      await unarchiveModel(machineId!, { trained_model_id: unarchiveId! });
      enqueueSnackbar(tt("unarchived"));
      setUnarchiveId(null);
    } catch (e) {
      enqueueSnackbar(tt("errorUnarchiving"));
    }
    setMachineId(null);
  };

  const hideKebabMenu = useCallback((id: string) => {
    const hideKebab = new CustomEvent("hideKebab");
    const kebab = document.querySelector(
      "#kebab-custom-model-deployments-" + id
    );
    kebab?.dispatchEvent(hideKebab);
  }, []);

  const generateItems = useMemo(() => {
    return (customModel: any) => {
      return (
        <>
          {customModel.is_archived ? (
            <MenuItem
              onClick={() => {
                setUnarchiveId?.(customModel.trained_model);
                setMachineId?.(customModel.deployments[0].consumer.machine);
                hideKebabMenu(customModel.task_id);
              }}
            >
              {t("machine.detail.model.unarchive")}
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                setArchiveId?.(customModel.trained_model);
                setMachineId?.(customModel.deployments[0].consumer.machine);
                hideKebabMenu(customModel.task_id);
              }}
            >
              {t("machine.detail.model.archive")}
            </MenuItem>
          )}
        </>
      );
    };
  }, [t]);

  return (
    <>
      <thead className={classes.break} />
      <TableBody className={classes.tableContent}>
        <TableRow className={classes.tr}>
          <TableCell className={classes.td}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setIsTaskId(!isTaskId);
              }}
            >
              {isTaskId ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {deploymentData.custom_model_category}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {deploymentData.datasetName}
          </TableCell>
          <TableCell
            className={`${classes.smallPadding} ${classes.td}`}
            sx={{
              maxWidth: "130px",
            }}
          >
            <Tooltip
              title={deploymentData.custom_model_training_name}
              key={deploymentData.custom_model_training_name}
            >
              <div style={{ textOverflow: "ellipsis", overflowX: "clip" }}>
                {deploymentData.custom_model_training_name}
              </div>
            </Tooltip>
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {deploymentData.dataset_group}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {deploymentData.trained_model}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {deploymentData.datasetModifiedAt
              ? moment(deploymentData.training_invoked_at).format(
                  "YYYY-MM-DD HH:mm"
                )
              : null}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {moment(deploymentData.training_finished_at).format(
              "YYYY-MM-DD HH:mm"
            )}
          </TableCell>
          <TableCell className={`${classes.smallPadding} ${classes.td}`}>
            {status
              ? t("models.runningOptions.running")
              : t("models.runningOptions.not_running")}
          </TableCell>

          <TableCell
            align="center"
            className={`${classes.smallPadding} ${classes.td}`}
          >
            <TooltipComponent
              title={toolTipInnerInfo}
              onOpen={() => {
                setOpenOtherInfoTooltip(!openOtherInfoTooltip);
              }}
            >
              <InfoRounded />
            </TooltipComponent>
          </TableCell>
          <TableCell
            align="center"
            className={`${classes.smallPadding} ${classes.td}`}
          >
            <KebabMenu
              id={"kebab-custom-model-deployments-" + deploymentData.task_id}
              data={generateItems(deploymentData)}
            />
          </TableCell>
        </TableRow>
        {isTaskId ? (
          <TableRow>
            <TableCell
              className={`${classes.smallPadding} ${classes.td}`}
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
              colSpan={11}
            >
              <Table className={classes.insdeTable}>
                <CustomModelDetailHead isTraining={false} />
                {group.deployments.map((item: ICustomModel) =>
                  item.deployments?.map((deployment) => (
                    <CustomModelDetailBodyDeployments
                      modelDeployment={item}
                      deployment={deployment}
                    />
                  ))
                )}
              </Table>
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}
      </TableBody>
      {archiveId && machineId && (
        <ConfirmPopup
          onConfirm={handleArchive}
          title={tt("archiveDialog.title")}
          text={tt("archiveDialog.text")}
          confirmText={t("machine.detail.model.archive")}
          noControl={true}
          onCancel={() => {
            setArchiveId(null);
            setMachineId(null);
          }}
        />
      )}
      {unarchiveId && machineId && (
        <ConfirmPopup
          onConfirm={handleUnarchive}
          title={tt("unarchiveDialog.title")}
          text={tt("unarchiveDialog.text")}
          confirmText={t("machine.detail.model.unarchive")}
          noControl={true}
          onCancel={() => {
            setUnarchiveId(null);
            setMachineId(null);
          }}
        />
      )}
    </>
  );
};

export default CustomModelBodyDeployment;
