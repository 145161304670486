import { memo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import isEqual from "react-fast-compare";
import SingleActionModal from "components/singleActionModal";
import trainModel from "api/handlers/trainModel";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import CustomModelCategorySelect from "./customModelCategorySelect";
import { fetchDatasets } from "store/eventSounds/actions";
import actions from "store/customModelCategory/actions";
import Box from "@mui/material/Box";
import { Button, TextField } from "@mui/material";
import { IRegressionTargets } from "store/customModelCategory/types";

const { setNominalClasses } = actions;

const isValidNumber = (value: IRegressionTargets) =>
  typeof value === "number" ||
  (typeof value === "string" && /^-?\d+(\.\d+)?$/.test(value));

const TrainingModel = memo(
  ({
    closeModal,
    id,
    open,
    datasetClasses,
  }: {
    id: number;
    closeModal: () => void;
    open: boolean;
    datasetClasses: { dataset: number }[];
  }) => {
    const { t } = useTranslation();
    const dispatch: any = useDispatch();
    const tt = useCallback((i: string) => t(`groupedDataset.${i}`), [t]);
    const { enqueueSnackbar } = useSnackbar();
    const {
      modelCategory,
      modelUrls,
      nominalClasses,
      regressionTargets,
      traceId,
      customName,
    } = useSelector((state: AppState) => state.customModelCategory);
    const { datasets } = useSelector((state: AppState) => state.eventSounds);

    const handleTrain = async () => {
      closeModal();
      try {
        await trainModel({
          datasetGroupId: id,
          trainingType:
            modelCategory === "regressor"
              ? "REGRESSION"
              : modelCategory === "anomaly_detector"
                ? "GAD"
                : "CC",
          modelCategory,
          modelUrls,
          regressionTargets:
            modelCategory === "regressor" ? regressionTargets : undefined,
          nominalClasses:
            modelCategory === "anomaly_detector" ? nominalClasses : undefined,
          traceId,
          customName,
        });
        enqueueSnackbar(t("machine.detail.oneClickTraining.modal.success"));
      } catch (e) {
        enqueueSnackbar(t("machine.detail.oneClickTraining.modal.fail"));
      }
    };

    const onChangeCustomName = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
          actions.setCustomName(e.target.value.length ? e.target.value : null)
        );
      },
      [dispatch]
    );

    useEffect(() => {
      if (open && datasetClasses.length) {
        const datasetIds = datasetClasses.map((item) => item.dataset);
        dispatch(fetchDatasets({ ids: datasetIds }));
        if (modelCategory === "anomaly_detector") {
          dispatch(setNominalClasses(datasetIds));
        }
      }
    }, [dispatch, datasetClasses, open, modelCategory]);

    useEffect(() => {
      if (
        open &&
        datasetClasses.length &&
        modelCategory === "anomaly_detector"
      ) {
        const datasetIds = datasetClasses.map((item) => item.dataset);

        dispatch(setNominalClasses(datasetIds));
      }
    }, [dispatch, datasetClasses, open, modelCategory]);

    const filterValidNumbers =
      regressionTargets.length &&
      regressionTargets.filter(
        (item: any) => !isValidNumber(item.numeric_target)
      );

    const datasetClass =
      modelCategory === "anomaly_detector"
        ? !nominalClasses.length
        : modelCategory === "regressor"
          ? !regressionTargets.length && !filterValidNumbers.length
          : false;

    return (
      <SingleActionModal
        open={open}
        modalTitle={tt("dataset.titles.train")}
        finishButtonLabel={tt("dataset.actions.train")}
        handleFinish={handleTrain}
        closeModal={closeModal}
        submitDisabled={
          !modelCategory.length ||
          !modelUrls.length ||
          datasetClass ||
          (modelCategory === "regressor" && regressionTargets.length < 2) ||
          !customName
        }
        loading={datasets.isFetching}
      >
        <TextField
          value={customName}
          onChange={onChangeCustomName}
          label={t("oneClick.customName.title")}
          variant="outlined"
          fullWidth
          style={{ marginBottom: "2rem" }}
        />
        <Box style={{ marginBottom: "2rem" }}>
          <div>
            {t("oneClick.traceId.note")} <span>{traceId}</span>
          </div>
          <Button
            size="small"
            style={{ paddingLeft: "unset" }}
            onClick={() =>
              window.open(
                `https://kibana.neuronsw.com/app/discover#/?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-7d,to:now))&_a=(columns:!(),filters:!(),index:'53fa74a0-1839-11ef-82a0-4d9ce61a0355',interval:auto,query:(language:kuery,query:'trace.id:%20%22${traceId}%22'),sort:!(!('@timestamp',desc)))
`,
                "_blank"
              )
            }
          >
            {t("oneClick.traceId.link")}
          </Button>
        </Box>
        <CustomModelCategorySelect datasetClasses={datasets?.data?.results} />
      </SingleActionModal>
    );
  },
  isEqual
);

export default TrainingModel;
