import React, { useCallback, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import values from "lodash/values";
import isEqual from "lodash/isEqual";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { DATE_FORMAT, TIME_FORMAT } from "components/dataLabeling/constants";
import { handleBufferDownload } from "components/dataLabeling/helpers";

import { AppState } from "store";
import actions from "store/machineDetail/sounds/actions";

import { useTranslation } from "react-i18next";

import MinuteNavigationBar from "components/dataLabeling/player/MinuteNavigationBar";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import ButtonGroup from "@mui/material/ButtonGroup";
import GetAppIcon from "@mui/icons-material/GetApp";
import Button from "@mui/material/Button";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import ToggleButtonGroup from "@mui/lab/ToggleButtonGroup";
import ToggleButton from "@mui/lab/ToggleButton";
import Switch from "components/switch";

interface Props {
  navigate: (arg: any) => any;
}

const useStyles = makeStyles((theme: any) => ({
  gridWrap: {
    flexWrap: "wrap",
    [theme.breakpoints.up("lg")]: {
      flexWrap: "nowrap",
    },
  },
  gridItem: {
    position: "relative",
    margin: 10,
    paddingTop: "16px",
    width: "100%",
  },
  root: {
    width: "100%",
  },
  selectLabel: {
    marginBottom: "8px",
    width: "100%",
  },
  buttonGroup: {
    backgroundColor: theme.custom.palette.secondary,
    color: theme.custom.palette.primary["100"],
    boxShadow: theme.custom.boxShadow["2"],
    "& > .MuiButton-label": {
      flexWrap: "wrap",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "10px",
  },
  toggleButtonGroup: {
    gap: 0,
    marginLeft: 10,
    marginRight: 10,
    "& > :nth-child(n)": {
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: 4,
    },
  },
}));

const LabelComponent = ({ navigate }: Props) => {
  const dispatch: any = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    bufferStart,
    draw,
    date,
    time,
    machine,
    timezoneOffset,
    duration,
    placements,
    localZoom,
    editMode,
  } = useSelector((state: AppState) => state.machineDetail.sounds, isEqual);

  const onChangeBuffer = (seconds: number) => {
    const start = bufferStart + seconds;
    const newDate = moment.unix(start).format(DATE_FORMAT);
    navigate({
      timezoneOffset,
      date: newDate,
      time: moment.unix(start).format(`${TIME_FORMAT}:ss`),
      duration,
    });
  };

  const onChangeDraw = useCallback(
    (e: any, value: string) => {
      dispatch(actions.setDraw(value));
    },
    [dispatch]
  );

  const disabledDownload = useMemo(() => {
    return (
      !values(placements).every((placement: any) => !placement.loadingBuffer) ||
      !values(placements).some((item: any) => item?.buffer?.buffer)
    );
  }, [placements]);

  const onDownload = useCallback(() => {
    if (date && time && machine) {
      handleBufferDownload(machine, date, time, placements, localZoom);
    }
  }, [machine, date, time, placements, localZoom]);

  const scrollToAudio = () => {
    const element = document.querySelector('div[id^="supervisedArea-"]');
    if (!disabledDownload && element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Box className={classes.container}>
      <MinuteNavigationBar onChangeBuffer={onChangeBuffer} />
      <ButtonGroup variant="text" aria-label="outlined primary button group">
        <Box display="flex" alignItems="center">
          <Switch
            name="switch"
            checked={editMode}
            onChange={() => {
              dispatch(actions.setEditMode(!editMode));
            }}
            disabled={false}
            labelOn={t("taggingapp.button.edit")}
            labelOff={t("taggingapp.button.view")}
            neutral
          />
        </Box>
        <Button
          data-cy="scrollToAudio"
          className={classes.buttonGroup}
          startIcon={<GraphicEqIcon />}
          disabled={disabledDownload}
          onClick={scrollToAudio}
        >
          {t("taggingapp.button.scrollToAudio")}
        </Button>
        <ToggleButtonGroup
          exclusive
          onChange={onChangeDraw}
          value={draw}
          size="small"
          classes={{ root: classes.toggleButtonGroup }}
          disabled={disabledDownload}
        >
          <ToggleButton value="wave">
            {t("taggingapp.button.waveform")}
          </ToggleButton>
          <ToggleButton
            style={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              borderRadius: 4,
            }}
            value="spectrogram"
          >
            {t("taggingapp.button.spectogram")}
          </ToggleButton>
          <ToggleButton
            style={{
              border: "1px solid rgba(0, 0, 0, 0.12)",
              borderRadius: 4,
            }}
            value="energy"
          >
            {t("taggingapp.button.energy")}
          </ToggleButton>
        </ToggleButtonGroup>
        <Button
          className={classes.buttonGroup}
          onClick={onDownload}
          disabled={disabledDownload}
          startIcon={<GetAppIcon />}
        >
          {t("taggingapp.button.download")}
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default LabelComponent;
